//
// Stepper Links
//

// Base
.stepper.stepper-links {
  .stepper-nav {
    display: flex;
    margin: 0 auto;
    padding-top: 4.25rem;
    overflow: auto;

    .stepper-item {
      margin-right: 3rem;
      position: relative;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: ' ';
        position: absolute;
        top: 2.3rem;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: transparent;
        transition: $transition-link;
      }

      .stepper-title {
        color: $dark;
        font-weight: 600;
        font-size: 1.25rem;
      }

      // Current
      &.current {
        transition: $transition-link;

        .stepper-title {
          color: $primary;
        }

        &:after {
          background-color: $primary;
        }
      }

      &.completed {
        .stepper-title {
          color: $gray-400;
        }
      }
    }
  }
}
