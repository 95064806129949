#kt_header{
  background: #ffffff;
  border-bottom: 2px solid #FE9B02;
}
#kt_content_container{
  max-width: 100%;
}

#kt_docs_aside{
  background: #1e1e2d;
}
.menu-item .menu-link .menu-title{
  font-size:1.2rem;
}
.menu-title-gray-800 .menu-item .menu-link .menu-title{
  color: #ffffff;
}
.table > thead{
  background-color: #FE9B02;
  font-weight: bold;
}
.logo{
  max-width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 22px;
}
.menu-state-title-primary .menu-item .menu-link.active .menu-title{
  color: #FE9B02;
}
.table tr, .table th, .table td{
  font-size: 1.1rem;
}
.c-loading{
  color: #FE9B02;
  text-align: center;
  font-size: 20px;
}
.Toastify__toast-theme--light{
  color: #181c32;
  background-color: #FE9B02;
}
.right-spacer-10{
  margin-right: 10px;
}
.th-table td a{
  text-align: center;
}